import React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Banner from "../components/banner"
import Case_study from "../components/case_study"
import Seo from "../components/seo"
import {graphql, Link } from "gatsby"



const ServiceDetail = ({ data }) => {
 
   // console.log('new', data);
   const case_study_slider = data.allWpCaseStudySlider;
    const serviceMainTItle = data.wpEcommerceService.ecommerceServices.serviceMainTitle;
    console.log('ServiceTitle', serviceMainTItle);
    const org_data = []; 
    serviceMainTItle.map((serviceMainTItle) => {

      org_data.push(serviceMainTItle.slideText,parseInt(serviceMainTItle.slideTime));

    })
    //console.log('push', org_data);




 return (

  <Layout>
<Seo title={data.wpEcommerceService.title} />
 
<Banner className = "service" title = {org_data}  sticky_first = {data.wpEcommerceService.ecommerceServices.serviceTagLine} />

<section className = "introduction">

        <div className = "container">
        <div  dangerouslySetInnerHTML={{ __html: data.wpEcommerceService.ecommerceServices.serviceIntroduction }} className = "intro">
         
</div>
        </div>


</section>

<section className = "shopify_services_tab">

<div className = "container">
<div className = "batches">

  <ul>
  
      {
      
      data.wpEcommerceService.ecommerceServices.serviceBatches.map(batch => {
     
      
     return <li key = {batch.batchName} > 

<GatsbyImage
      image={getImage(batch.batchImage.localFile)}
      quality = "100"
      alt={batch.batchName} />
</li>})
      }
      
      
      </ul>
</div>

<div dangerouslySetInnerHTML={{ __html: data.wpEcommerceService.ecommerceServices.servicePartIntroduction }} >
  
</div>


<div className="tabs">
	<ul id="tab-links">

    { data.wpEcommerceService.ecommerceServices.serviceParts.map((tab,ind) => {


return  <li key = {tab.serviceName}>
  

       
<a href={`#tab-${ind}`} className = {ind == 0 ? "active" : "" } title="Code">
{tab.serviceName}
    
</a></li>

    })}
    
	</ul>

  { data.wpEcommerceService.ecommerceServices.serviceParts.map((service,index) => {


return <section id= {`tab-${index}`} className = {index == 0 ? "active" : "" } key = {service.serviceName}>
<h3>{service.serviceName}</h3>

<div dangerouslySetInnerHTML={{ __html: service.serviceDetail }} ></div>


</section>


  })}
	


</div>

</div>

</section>


<section className ="custom_service">

  <div className ="container">

    <div className = "service_detail_wrap">
      <div className = "service_detail">
          <h2>{data.wpEcommerceService.ecommerceServices.customService.serviceTitle}</h2>
          <p>{data.wpEcommerceService.ecommerceServices.customService.serviceDetail}</p>
      </div>
    <div className ="service_image">
    <GatsbyImage
      image={getImage(data.wpEcommerceService.ecommerceServices.customService.serviceImage.localFile)}
      quality = "100"
      alt={data.wpEcommerceService.ecommerceServices.customService.serviceTitle} />


    </div>


      </div>


  </div>




</section>


<section className = "e_company">

<div className ="container">

  <div className ="c_area">

    <div className ="co_img">
    <GatsbyImage
      image={getImage(data.wpEcommerceService.ecommerceServices.companyIntroduction.introductionImage.localFile)}
      quality = "100"
      alt={data.wpEcommerceService.ecommerceServices.companyIntroduction.title} />

    </div>
    <div className = "co_cont">

    <div dangerouslySetInnerHTML={{ __html: data.wpEcommerceService.ecommerceServices.companyIntroduction.title}} ></div>
   
      <p>{data.wpEcommerceService.ecommerceServices.companyIntroduction.detail}</p>



    </div>


  </div>


</div>

</section>



<section className = "stack_lang">
<div className ="container">

<div className = "batches">

  <ul>

  {
      
      data.wpEcommerceService.ecommerceServices.serviceTechnologies.map(service => {
     
      
     return <li key = {service.stackName}> 

<GatsbyImage
      image={getImage(service.stackImage.localFile)}
      quality = "100"
      alt={service.stackName} 
      />
</li>})
      }


  
    
     
   
      </ul>
</div>

</div>


</section>

<Case_study data_case_slider = {case_study_slider}  />

<section className = "service_types">


    <div className = "container">

      <div className = "serv_wrapper">


      {
      
      data.wpEcommerceService.ecommerceServices.serviceMainHighlights.map(service_highlight => {
     


      return  <div className = "serv_box" >


<GatsbyImage
      image={getImage(service_highlight.highlightImage.localFile)}
      quality = "100"
      alt={service_highlight.highlightTitle}
      />


      <div className ="serv_info">

        <h2>{service_highlight.highlightTitle}</h2>
        <p>
       {service_highlight.highlightDetails}

        </p>

      </div>
    </div>
})
}
      
</div>
</div>
</section>

<section className = "schedule_call">


      <div className ="container">

      <div dangerouslySetInnerHTML={{ __html: data.wpEcommerceService.ecommerceServices.scheduleCall.description}} ></div>
        
        <Link target = "_blank" to ={data.wpEcommerceService.ecommerceServices.scheduleCall.buttonLink} className ="sc_call" >{data.wpEcommerceService.ecommerceServices.scheduleCall.buttonTitle}</Link>

      </div>



</section>













</Layout>



    
   
  );

}

export const singlepost = graphql`
query singlePost($slug: String) {
  wpEcommerceService(slug: {eq: $slug}) {
    title
    ecommerceServices {
      
      serviceIntroduction
      serviceBatches {
        batchName
        batchImage {
          localFile {
           childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED,quality: 100)
            }
          }
        }
      }
      servicePartIntroduction
      serviceParts {
        serviceName
        serviceDetail
      }
      customService {
        serviceTitle
        serviceDetail
        serviceImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED,quality: 100)
            }
          }
        }
      }
      companyIntroduction {
        title
        detail
        introductionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED,quality: 100)
            }
          }
        }
      }
      serviceTechnologies {
        stackName
        stackImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 90,quality: 100)
            }
          }
        }
      }
      serviceMainHighlights {
        highlightTitle
        highlightDetails
        highlightImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 200,quality: 100)
            }
          }
        }
      }
      scheduleCall {
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED,quality: 100)
            }
          }
        }
        description
        buttonTitle
        buttonLink
      }
      serviceMainTitle {
        slideText
        slideTime
      }
      serviceTagLine
    }
  }

  allWpCaseStudySlider {
    edges {
      node {
        caseStudySlider {
          projectName
          projectStack
          projectLogo {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED,  width: 70,quality: 100)
              }
            }
          }
          projectImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED,quality: 100)
              }
            }
          }
        }
      }
    }
  }
}

  
  `





  

  export default ServiceDetail;